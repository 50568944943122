/* 7.6. Koenig Styles
/* ---------------------------------------------------------- */

.post-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 768px;
}

.post-template .post-content > p:first-child {
    font-size: 1.25em;
    line-height: 1.5em;
}

.post-full-content .kg-image {
    max-width: 100%;
}

/* Preventing full-width image overlap with post image.  */
.post-full-image + .post-full-content .kg-content *:first-child .kg-image {
    width: 100%;
}

.post-full-content .kg-width-wide .kg-image {
    max-width: 768px;
}

.post-full-content .kg-width-full .kg-image {
    max-width: 100vw;
}

.post-content figcaption {
    font-size: 80%;
    line-height: 1.6em;
    text-align: center;
}

.kg-gallery-image & img {
    width: 100% !important;
}

.kg-image-card,
.kg-gallery-card {
    margin: 0 0 1.5em;
}

.kg-image-card figcaption,
.kg-gallery-card figcaption {
    margin: -1.0em 0 1.5em;
}

.kg-embed-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.5em 0 3em;
    min-width: 100%;
}

.kg-embed-card figcaption {
    margin: 0.5em 0 0;
}

.kg-embed-card .fluid-width-video-wrapper {
    margin: 0;
}


.kg-image-full + figcaption {
    padding: 0 1.5em;
}


@media (max-width: 1040px) {
    .post-full-content .kg-width-full .kg-image {
        width: 100vw;
    }
}

.kg-gallery-container {
    display: flex;
    flex-direction: column;
    margin: 1.5em auto;
    max-width: 768px;
    /* width: 100vw; */
}

.kg-gallery-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.kg-gallery-image img {
    display: block;
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 768px;
}

.kg-gallery-row:not(:first-of-type) {
    margin: 0.75em 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
    margin: 0 0 0 0.75em;
}

.fluid-width-video-wrapper {
    width: 100%;
    position: relative;
    padding: 0;
}

.post-content, iframe {
    width:100%;
}

